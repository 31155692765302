
// Inspired by https://alligator.io/vuejs/file-reader-component/

import { Component, Vue, Prop } from "vue-property-decorator"

import FLButton from "@/components/base/FLButton.vue"
import IconButton from "@/components/base/IconButton.vue"

export type Vrefs = Vue & { click: () => void }

@Component({
  components: {
    FLButton,
    IconButton,
  },
})
export default class FLFileReader extends Vue {
  @Prop({ default: "Read file" }) label!: string
  @Prop({ default: "" }) accept!: string
  filename = ""
  inputFile = "inputFile"
  index = 0

  loadTextFromFile(ev: any) {
    if (!ev || !ev.target) {
      return
    }
    const file = ev.target.files[0]
    this.filename = file.name
    const reader = new FileReader()
    reader.onload = (e: any) => {
      this.$emit("load", { name: this.filename, data: e.target.result })
    }
    reader.readAsText(file)
  }

  click() {
    ;(this.$refs.fileinput as Vrefs).click()
  }

  remove() {
    this.$emit("load", { name: null, data: null })
    this.filename = ""
    this.inputFile = this.inputFile + this.index++
  }
}
