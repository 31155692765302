
import Vue from "vue"
import { Component } from "vue-property-decorator"
import { SET_PAGE_TITLE } from "@/store/store-types"
import Backup from "@/components/Backup.vue"
import LocaleChanger from "@/components/LocaleChanger.vue"
import { FLButton } from "src/components/base/FLButton.vue"
import { FLToggle } from "@/components/base/FLToggle.vue"
import { COMBAT_BOX_KEY, MULTIPLAY_KEY } from "src/util/const"

@Component({
  components: {
    Backup,
    FLButton,
    FLToggle,
    LocaleChanger,
  },
})
export default class DiceView extends Vue {
  mounted() {
    this.$store?.commit(SET_PAGE_TITLE, "Options")
  }

  update() {
    // window.location.replace("/")
    window.location.reload()
  }

  get combatBoxEnabled() {
    return !!localStorage.getItem(COMBAT_BOX_KEY)
  }
  setCombatBox(val: boolean) {
    if (val) {
      localStorage.setItem(COMBAT_BOX_KEY, String(val))
    } else {
      localStorage.removeItem(COMBAT_BOX_KEY)
    }
  }

  get multiplayEnabled() {
    return !!localStorage.getItem(MULTIPLAY_KEY)
  }
  setMultiplay(val: boolean) {
    if (val) {
      localStorage.setItem(MULTIPLAY_KEY, String(val))
    } else {
      localStorage.removeItem(MULTIPLAY_KEY)
    }
    this.update()
  }

  navBack() {
    this.$router.back()
  }
}
