
import { Component, Vue } from "vue-property-decorator"
import { setTimeout } from "timers"
import FLButton from "@/components/base/FLButton.vue"
import FileReader from "@/components/FileReader.vue"

function generateDateString(): string {
  return "backup-" + new Date().toISOString() + ".flcdata"
}

@Component({
  components: {
    FileReader,
    FLButton,
  },
})
export default class Backup extends Vue {
  importKey = 1

  importData: any = null

  exportBlob: string = ""
  exportFilename: string = ""

  generateBlob() {
    const exportString = JSON.stringify({ ...localStorage })
    const blob = new Blob([exportString])
    const blobUrl = window.URL.createObjectURL(blob)
    setTimeout(() => {
      window.URL.revokeObjectURL(blobUrl)
      this.exportFilename = ""
      this.exportBlob = ""
    }, 24000)
    this.exportBlob = blobUrl
    this.exportFilename = generateDateString()
    this.$nextTick(() => {
      const downloadLinkRef: any = this.$refs.downloadLink
      try {
        downloadLinkRef.click()
      } catch {
        this.$notify({
          type: "error",
          message:
            "Data NOT downloaded; use download link to download manually",
        })
      }
    })
    return this.exportBlob
  }

  releaseBlob() {
    window.URL.revokeObjectURL(this.exportBlob)
    this.exportFilename = ""
    this.exportBlob = ""
  }

  downloadClicked() {
    this.$notify({
      type: "info",
      message: "Data downloaded",
      displayTime: 3000,
    })
    // Need small timeout to be able to download before releasing blob object:
    setTimeout(() => this.releaseBlob(), 500)
  }

  importDataLoaded(filedata: any) {
    this.importData = JSON.parse(filedata.data)
  }

  importBackup() {
    if (!this.importData) return
    if (this.importData.savedCharacters) {
      // new import
      Object.entries(this.importData).map(([key, val]) => {
        localStorage.setItem(key, String(val))
      })
    } else {
      // old import
      this.$characterStore.replaceData(this.importData, true)
    }

    this.importData = null
    this.importKey++
    this.$router.push("/")
    // Importing and overwriting all stored data is a fairly destructive action,
    // so forcing a reload is the safest alternative:
    window.location.reload()
  }
}
