
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import SvgIcon from "@/components/SvgIcon.vue"

// TODO: SUpport label prefix/suffix

@Component({
  components: {
    SvgIcon,
  },
})
export class FLToggle extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: "" }) label!: string
  @Prop({ default: "" }) suffix!: string
  @Prop({ default: "" }) icon!: string
  @Prop({ default: "" }) id!: string
  @Prop({ default: false }) column!: boolean
  @Prop({ default: false }) dangerOff!: boolean
  @Prop({ default: false }) dangerOn!: boolean
  @Prop({ default: false }) block!: boolean

  randId = Math.random()
  get mId() {
    if (this.id) return this.id
    return `${this.randId}-${this.label}`
  }

  inputEvent($event: any) {
    this.$emit("input", $event.target.checked)
  }
}

export default FLToggle
